import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image';
import axios from 'axios';

import ContactForm from '../contact-form/contact-form';

import * as ContactSectionS from './contact-section.module.scss';
import * as TextS from '../../assets/styles/text.module.scss';
import * as WrapperS from '../../assets/styles/wrapper.module.scss';

const ContactSection = () => {

    const data = useStaticQuery(graphql`
        query{
            effect_down: file(relativePath: { eq: "images/effect_down.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, formats: [JPG], quality: 100)
                }
            }
            effect_up: file(relativePath: { eq: "images/effect_up.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, formats: [JPG], quality: 100)
                }
            }
        }
    `);

    const [projectsLeft, setProjectsLeft] = useState(2);

    useEffect(() => {

        const getData = async () => {

            try{
                const res = await axios.get('https://web-professor.pl/API/get-projects-left.php');
                setProjectsLeft(res.data);
            } catch (err){
            }
        }
        getData();


    }, []);

    return (
        <section className={ContactSectionS.section_wrapper}>

            <GatsbyImage
                className={ContactSectionS.effect_up}
                image={data.effect_up.childImageSharp.gatsbyImageData}
                alt=''
            />

            <GatsbyImage
                className={ContactSectionS.effect_down}
                image={data.effect_down.childImageSharp.gatsbyImageData}
                alt=''
            />

            <div className={`${ContactSectionS.content} ${WrapperS.apply}`}>

                <div className={ContactSectionS.before_form}>

                    <h2>Szybka wycena</h2>

                    <div className={`${TextS.subtitle} ${ContactSectionS.subtitle}`}>
                        Opowiedz nam o swoim projekcie i otrzymaj niezobowiązującą ofertę w 24h.
                    </div>

                    <hr className={ContactSectionS.separator}/>

                    <div className={`${TextS.before_title} ${ContactSectionS.before_title}`}>
                        NIE CZEKAJ!
                    </div>

                    <h3>W tym miesiącu jesteśmy w stanie przyjąć jeszcze tylko
                        <span className={TextS.green_highlight}> {projectsLeft} projekty.</span>
                    </h3>

                </div>

                <div className={ContactSectionS.form_wrapper}>
                    <ContactForm />
                </div>


            </div>

        </section>
    );
}

export default ContactSection;