import React, { useEffect, useState, useRef } from 'react';
import { useDebouncedCallback  } from 'use-debounce';

import CtaBtn from '../../ui-components/buttons/cta/cta';

import * as OfferSectionS from './offer-section.module.scss';
import * as TextS from '../../assets/styles/text.module.scss';
import * as WrapperS from '../../assets/styles/wrapper.module.scss';

import Analiza from '../../assets/images/icons/Analiza.svg';
import Architektura from '../../assets/images/icons/Architektura.svg';
import ProjektGraficzny from '../../assets/images/icons/Projekt Graficzny.svg';
import StronaInternetowa from '../../assets/images/icons/Strona internetowa.svg';
import Pozycjonowanie from '../../assets/images/icons/Pozycjonowanie.svg';
import Wsparcie from '../../assets/images/icons/Wsparcie.svg';


const OfferSection = () => {

    const [activeTile, setActiveTile] = useState(0);

    const tile1 = useRef();
    const tile2 = useRef();
    const tile3 = useRef();
    const tile4 = useRef();
    const tile5 = useRef();
    const tile6 = useRef();

    const calcViewport = () => {

        //tile1
        const tile1_top = tile1.current.getBoundingClientRect().top;
        if(tile1_top <= 350 && tile1_top > 0){
            setActiveTile(1);
            return;
        }

        //tile2
        const tile2_top = tile2.current.getBoundingClientRect().top;
        if(tile2_top <= 350 && tile2_top > 0){
            setActiveTile(2);
            return;
        }

        //tile3
        const tile3_top = tile3.current.getBoundingClientRect().top;
        if(tile3_top <= 350 && tile3_top > 0){
            setActiveTile(3);
            return;
        }

        //tile4
        const tile4_top = tile4.current.getBoundingClientRect().top;
        if(tile4_top <= 350 && tile4_top > 0){
            setActiveTile(4);
            return;
        }

        //tile5
        const tile5_top = tile5.current.getBoundingClientRect().top;
        if(tile5_top <= 350 && tile5_top > 0){
            setActiveTile(5);
            return;
        }

        //tile6
        const tile6_top = tile6.current.getBoundingClientRect().top;
        if(tile6_top <= 350 && tile6_top > 0){
            setActiveTile(6);
            return;
        }

        setActiveTile(0);

    }

    const handleScroll = useDebouncedCallback(
        () => {

            const tile1_top = tile1.current.getBoundingClientRect().top;
            const tile6_top = tile6.current.getBoundingClientRect().top;

            if(tile1_top < window.innerHeight && tile6_top > 0){
                calcViewport();
            } else {
                setActiveTile(0);
            }

        }, 10
    )

    useEffect(() => {

        window.addEventListener("scroll", handleScroll);

    }, [handleScroll])


    return (
        <section className={`${OfferSectionS.section_wrapper} ${WrapperS.apply}`}>


            <div className={OfferSectionS.header}>

                <div className={TextS.before_title}>STRONA SZYTA NA MIARĘ</div>
                <h2>Co dokładnie <span className={TextS.highlight}>otrzymam?</span></h2>

                <p className={`${TextS.subtitle} ${OfferSectionS.subtitle}`}>
                    Nasz proces projektowy składa sie się z <b>6 kluczowych elementów</b>, których
                    wynikiem jest profesjonalna, szyta na miarę strona internetowa.
                </p>

                <CtaBtn classes={OfferSectionS.cta_desktop}>Szybka wycena</CtaBtn>

            </div>



            <div className={OfferSectionS.content}>

                <ul>

                    {/* 01 */}
                    <li className={`${OfferSectionS.tile} ${activeTile === 1 && OfferSectionS.active}`}
                        ref={tile1}
                    >

                        <div className={OfferSectionS.number}>01</div>

                        <div className={OfferSectionS.tile_header}>

                            <img src={Analiza} alt="Analiza icon" className={OfferSectionS.icon} />

                            <h3>Profesjonalną analizę możliwości i doradztwo</h3>

                        </div>

                        <p>
                            Naszą pracę zaczniemy od dokładnego poznania Twojej firmy. Odkryjemy jej mocne strony.
                            Poznamy specyfikę branży i stworzymy profil idealnego klienta.
                        </p>

                        <p>
                            Na tej podstawie przeanalizujemy dostępne rozwiązania i pomożemy Ci wybrać odpowiednie funkcjonalności.
                        </p>

                    </li>

                    {/* 02 */}
                    <li className={`${OfferSectionS.tile} ${activeTile === 2 && OfferSectionS.active}`}
                        ref={tile2}
                    >

                        <div className={OfferSectionS.number}>02</div>

                        <div className={OfferSectionS.tile_header}>

                            <img src={Architektura} alt="Architektura icon" className={OfferSectionS.icon} />

                            <h3>Przemyślaną architekturę informacji</h3>

                        </div>

                        <p>
                            Informacje na Twojej nowej stronie ułożymy w specjalny sposób, nastawiony na bezwzględną skuteczność!
                        </p>

                        <p>
                            Planujemy przebieg każdego scenariusza wizyty. Klienci w wygodny sposób trafią na szukane informacje i podejmą dokładnie takie działania, jakich oczekujemy.
                        </p>

                    </li>

                    {/* 03 */}
                    <li className={`${OfferSectionS.tile} ${activeTile === 3 && OfferSectionS.active}`}
                        ref={tile3}
                    >

                        <div className={OfferSectionS.number}>03</div>

                        <div className={OfferSectionS.tile_header}>

                            <img src={ProjektGraficzny} alt="Projekt graficzny icon" className={OfferSectionS.icon} />

                            <h3>Nowoczesny projekt graficzny</h3>

                        </div>

                        <p>
                            Przygotujemy piękny, intuicyjny i funkcjonalny projekt graficzny całej strony internetowej.
                        </p>

                        <p>
                            Otrzymasz dokładny podgląd, który pozwoli na łatwe wprowadzanie ewentualnych poprawek.
                        </p>

                    </li>


                    {/* 04 */}
                    <li className={`${OfferSectionS.tile} ${activeTile === 4 && OfferSectionS.active}`}
                        ref={tile4}
                    >

                        <div className={OfferSectionS.number}>04</div>

                        <div className={OfferSectionS.tile_header}>

                            <img src={StronaInternetowa} alt="Strona internetowa icon" className={OfferSectionS.icon} />

                            <h3>Najwyższej jakości stronę internetową</h3>

                        </div>

                        <p>
                            Twoja strona będzie szybka, bezpieczna, responsywna i co najważniejsze - skuteczna!
                        </p>

                        <p>
                            Nasi programiści piszą kod zgodny z najnowszymi standardami, a każda
                            stworzona przez nich strona nagradzana jest topowymi wynikami w wielu testach.
                        </p>

                    </li>


                    {/* 05 */}
                    <li className={`${OfferSectionS.tile} ${activeTile === 5 && OfferSectionS.active}`}
                        ref={tile5}
                    >

                        <div className={OfferSectionS.number}>05</div>

                        <div className={OfferSectionS.tile_header}>

                            <img src={Pozycjonowanie} alt="Pozycjonowanie SEO icon" className={OfferSectionS.icon} />

                            <h3>Pozycjonowanie w cenie</h3>

                        </div>

                        <p>
                            Jeśli Google nie może Cię znaleźć, to nikt tego nie zrobi.
                            Dlatego dbamy o SEO na każdym etapie tworzenia projektu.
                        </p>

                        <p>
                            Wszystkie nasze strony osiągają 100-procentowy wynik w oficjalnym teście pozycjonowania od Google.
                        </p>

                    </li>


                    {/* 06 */}
                    <li className={`${OfferSectionS.tile} ${activeTile === 6 && OfferSectionS.active}`}
                        ref={tile6}
                    >

                        <div className={OfferSectionS.number}>06</div>

                        <div className={OfferSectionS.tile_header}>

                            <img src={Wsparcie} alt="Wsparcie icon" className={OfferSectionS.icon} />

                            <h3>Wsparcie nawet po zakończeniu projektu</h3>

                        </div>

                        <p>
                            Kończymy projekt, ale nasza współpraca się nie kończy!
                        </p>

                        <p>
                            Zapewniamy bieżący dostęp do specjalisty, który odpowie na Twoje pytania, zaktualizuje stronę i rozwiąże ewentualne problemy techniczne.
                        </p>


                    </li>


                </ul>

            </div>

            <CtaBtn classes={OfferSectionS.cta_mobile}>Szybka wycena</CtaBtn>

        </section>
    );

}

export default OfferSection;