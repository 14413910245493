import React from 'react';

import FaqElement from './faq-element/faq-element';

import * as FaqSectionS from './faq-section.module.scss';
import * as TextS from '../../assets/styles/text.module.scss';
import * as WrapperS from '../../assets/styles/wrapper.module.scss';

const FaqSection = () => {


    return (
        <section className={`${FaqSectionS.section_wrapper} ${WrapperS.apply}`}>

            <h2>Częste <span className={TextS.highlight}>pytania</span></h2>


            <ul className={FaqSectionS.faq_list}>

                <FaqElement
                    question={'Ile trwa stworzenie strony internetowej?'}
                >
                    <p>
                        Wszystko zależy od złożoności projektu.
                    </p>

                    <p>
                        Zazwyczaj trwa to w okolicach 4-5 tygodni od momentu ustalenia wszystkich szczegółów.
                        Proste strony internetowe jesteśmy w stanie ukończyć nawet w 2 tygodnie.
                    </p>

                    <p>
                        Na każdym etapie konsultujemy z Tobą nasze wyniki i to Ty decydujesz jak szybko
                        poruszamy się po procesie. Pamiętaj jednak, że często warto poświęcić więcej
                        czasu i dopiąć wszystko na ostatni guzik!
                    </p>
                </FaqElement>

                <FaqElement
                    question={'Ile kosztuje strona internetowa?'}
                >
                    <p>
                        Rozsądny budżet na stronę internetową to taki, który się opłaca.
                    </p>

                    <p>
                        Czyli pozwoli stworzyć skuteczne rozwiązanie, przyciągające klientów i szybko zwracające właścicielowi koszty inwestycji.
                    </p>

                    <p>
                        Wycena projektu zawsze dokonywana jest indywidualnie.
                        Aby poznać cenę swojego projektu skorzystaj z szybkiej i niezobowiązującej wyceny poniżej!
                    </p>
                </FaqElement>

                <FaqElement
                    question={'Skąd mam wiedzieć czy strona internetowa jest skuteczna?'}
                >
                    <p>
                        Nasze działania opieramy na prostych liczbach i faktach.
                        Mierzymy skuteczność wszystkich stworzonych przez nas stron.
                    </p>

                    <p>
                        Otrzymasz od nas dostęp do wygodnych narzędzi analitycznych, które pozwolą Ci
                        monitorować ilość użytkowników, źródło ich pochodzenia, konwersje i wiele więcej.
                    </p>
                </FaqElement>
{/*
                <FaqElement
                    question={'Czy naprawdę dajecie gwarancję 100% satysfakcji?'}
                >
                    <p>
                        Tak - klient płaci drugą ratę, tylko wtedy gdy jest w 100% zadowolony z efektów naszej pracy.
                    </p>

                    <p>
                        W odróżnieniu od konkurencji bierzemy pełną odpowiedzialność za jakość naszych stron internetowych.
	                    Jesteśmy dumni z tego, że możemy każdy nasz projekt objąć taką gwarancją.
                    </p>

                    <p>
                        Bez żadnych haczyków ani ukrytych opłat!
	                    Wszystkie warunki gwarancji są zawsze dokładnie opisane przy wycenie projektu.
                    </p>
                </FaqElement> */}

                <FaqElement
                    question={'Co z domeną, hostingiem i pocztą dla mojej firmy?'}
                >
                    <p>
                        Część klientów zgłaszając się do nas posiada już swój hosting, domenę lub pocztę email.
	                    Dlatego nie umieszczamy tych elementów bezpośrednio w ofercie.
                    </p>

                    <p>
                        Jeśli jednak ich potrzebujesz, pomożemy Ci je wybrać i skonfigurować bez żadnych dodatkowych opłat!
	                    Jedyny koszt jaki poniesiesz to zewnętrzny koszt zakupu hostingu i domeny - jest to kwota w okolicach 250zł za rok.
                    </p>

                    <p>
                        Nasza oferta oczywiście obejmuje również instalację i uruchomienie stworzonej przez nas strony internetowej na wybranym hostingu.
                    </p>
                </FaqElement>

                <FaqElement
                    question={'Dopiero zaczynam, czy to odpowiednia oferta dla małych firm?'}
                >
                    <p>
                        Tak - dobra strona internetowa pozwoli Twojej firmie na znacznie szybszy rozwój!
                    </p>

                    <p>
                        Staramy się zawsze dostosować do budżetu, tak aby zaproponowane przez nas rozwiązania przyniosły Ci jak najwięcej korzyści.
                    </p>
                </FaqElement>

            </ul>


        </section>
    );
}

export default FaqSection;