// extracted by mini-css-extract-plugin
export var section_wrapper = "conversion-section-module--section_wrapper--2aDrK";
export var subtitle = "conversion-section-module--subtitle--3nyPS";
export var calculator = "conversion-section-module--calculator--HkkvR";
export var top = "conversion-section-module--top--3Uicd";
export var content = "conversion-section-module--content--UDu5f";
export var line = "conversion-section-module--line--3TwQ3";
export var value = "conversion-section-module--value--2akO6";
export var improve = "conversion-section-module--improve--2nGL4";
export var summary = "conversion-section-module--summary--1Ax9a";
export var phone_img = "conversion-section-module--phone_img--i-YHD";
export var bottom = "conversion-section-module--bottom--1753O";
export var switch_wrapper = "conversion-section-module--switch_wrapper--2iTAA";
export var toggle = "conversion-section-module--toggle--NEIS1";