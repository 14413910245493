import * as React from "react"
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from "gatsby";

import Hero from "../components/hero/hero";
import MainLayout from "../hoc/main-layout";
import IntroTile from "../components/intro-tile/intro-tile";
import OfferSection from "../components/offer-section/offer-section";
import ConversionSection from "../components/conversion-section/conversion-section";
import StandOutSection from "../components/stand-out-section/stand-out-section";
import Opinions from "../components/opinions/opinions";
import FaqSection from "../components/faq-section/faq-section";
import ContactSection from "../components/contact-section/contact-section";

import * as IndexPageS from './index.module.scss';
import PortfolioSection from "../components/portfolio-section/portfolio-section";

//todo te komponenty tak żeby przyjmowały propsy i były reużywalne
const IndexPage = () => {

  const data = useStaticQuery(graphql`
    query {
      metaImg: file(relativePath: { eq: "images/metaImg.jpg" }) {
        publicURL
      }
    }
  `);


  return (
    <MainLayout>

      <Helmet
        htmlAttributes={{
          lang: `pl`,
        }}
        title='💻 Dochodowe strony internetowe i rozwiązania IT - WebProfessor.'
        meta={[
          {
            name: `description`,
            content: '✅ Budujemy trwałe relacje oparte na zaufaniu, usługach najwyższej jakości i wiedzy naszych ekspertów. Sprawdź, jak nasze usługi mogą pomóc w rozwoju Twojej firmy!',
          },
          {
            property: `og:url`,
            content: 'https://web-professor.pl',
          },
          {
            property: `og:title`,
            content: '💻 Dochodowe strony internetowe i rozwiązania IT - WebProfessor.',
          },
          {
            property: `og:image`,
            content: data.metaImg.publicURL,
          },
          {
            property: `og:description`,
            content: '✅ Budujemy trwałe relacje oparte na zaufaniu, usługach najwyższej jakości i wiedzy naszych ekspertów. Sprawdź, jak nasze usługi mogą pomóc w rozwoju Twojej firmy!',
          },
          {
            property: `og:type`,
            content: 'website',
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:title`,
            content: '💻 Dochodowe strony internetowe i rozwiązania IT - WebProfessor.',
          },
          {
            name: `twitter:description`,
            content: '✅ Budujemy trwałe relacje oparte na zaufaniu, usługach najwyższej jakości i wiedzy naszych ekspertów. Sprawdź, jak nasze usługi mogą pomóc w rozwoju Twojej firmy!',
          },
          {
            name: `twitter:image`,
            content: data.metaImg.publicURL,
          },
        ].concat()}
      />


      <Hero />

      <IntroTile />

      <OfferSection />

      <ConversionSection />

      {/* <Opinions /> */}
      <PortfolioSection />

      <StandOutSection />

      <FaqSection />

      <ContactSection />

    </MainLayout>
  )
}

export default IndexPage;
