import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image';

import CtaBtn from '../../ui-components/buttons/cta/cta';

import * as IntroTileS from './intro-tile.module.scss';
import * as TextS from '../../assets/styles/text.module.scss';

const IntroTile = () => {


    const data = useStaticQuery(graphql`
        query{
            ceo_photo: file(relativePath: { eq: "images/ceo_photo.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, formats: [JPG], quality: 80)
                }
            }
        }
    `);



    return (
        <section className={IntroTileS.section_wrapper}>

            <div className={IntroTileS.photo_desktop}>
                <GatsbyImage
                    className={IntroTileS.img}
                    image={data.ceo_photo.childImageSharp.gatsbyImageData}
                    alt='WebProfessor CEO photo'
                />

                <div className={IntroTileS.caption}>
                    <b>Rafał&nbsp;Adamski</b><br/>
                    CEO&nbsp;WebProfessor.
                </div>
            </div>

            <div className={IntroTileS.content}>

                <div className={TextS.before_title}>ZAUFAŁY NAM FIRMY Z CAŁEJ POLSKI</div>

                <h2>Tworzymy dochodowe strony internetowe i rozwiązania IT</h2>

                <div className={IntroTileS.photo_mobile}>
                    <GatsbyImage
                        className={IntroTileS.img}
                        image={data.ceo_photo.childImageSharp.gatsbyImageData}
                        alt='WebProfessor Właściciel photo'
                    />

                    <div className={IntroTileS.caption}>
                        <b>Rafał&nbsp;Adamski</b><br/>
                        CEO&nbsp;WebProfessor.
                    </div>
                </div>


                <p className={IntroTileS.text}>
                    Budujemy trwałe relacje oparte na zaufaniu, usługach najwyższej jakości i wiedzy naszych ekspertów.
                </p>

                <p className={IntroTileS.text}>
                    Sprawdź, jak możemy pomóc w rozwoju Twojej firmy!
                </p>

                <CtaBtn classes={IntroTileS.cta}>Porozmawiajmy!</CtaBtn>

            </div>

        </section>
    );
}

export default IntroTile;


