import React from 'react';

import * as PortfolioSectionS from './portfolio-section.module.scss';
import * as TextS from '../../assets/styles/text.module.scss';
import * as WrapperS from '../../assets/styles/wrapper.module.scss';
import Opinions from '../opinions/opinions';


const PortfolioSection = () => {


    return (
        <section className={`${PortfolioSectionS.section_wrapper} ${WrapperS.apply}`}>

            <div className={TextS.before_title}>ZOBACZ NASZE PROJEKTY W AKCJI</div>
            <h2>
                Realizacje - <span className={TextS.highlight}>Case studies</span>
            </h2>

            <Opinions />


        </section>
    );

}

export default PortfolioSection;