import React, { useState, useRef, useEffect } from 'react';
import Switch from 'react-switch';
import { useCountUp } from 'react-countup';

import * as ConversionSectionS from './conversion-section.module.scss';
import * as TextS from '../../assets/styles/text.module.scss';
import * as WrapperS from '../../assets/styles/wrapper.module.scss';
import './switch.scss';

import Logo from '../../assets/images/Logo-purple.svg';
import PhoneAnimation from '../../assets/video/conv.webm';
import PhoneAnimationMp4 from '../../assets/video/conv.mp4';

const ConversionSection = () => {

    const [checked, setChecked] = useState(false);

    const clientsRef = useRef(null);
    const revenueRef = useRef(null);
    const videoRef = useRef(null);

    const { start: startClients, reset: resetClients} = useCountUp({
        ref: clientsRef,
        start: 10,
        end: 30,
        startOnMount: false,
        duration: 2
    });

    const { start: startRevenue, reset: resetRevenue} = useCountUp({
        ref: revenueRef,
        start: 10000,
        end: 30000,
        startOnMount: false,
        duration: 3,
        suffix: ' zł'
    });

    useEffect(() => {

        if(checked){

            startClients();
            startRevenue();
            videoRef.current.play();

        } else {

            resetClients();
            resetRevenue();
            videoRef.current.load();

        }

    }, [checked])

    return (
        <section className={`${ConversionSectionS.section_wrapper} ${WrapperS.apply}`}>

            <div className={TextS.before_title}>NASZE STRONY RELIZUJĄ CELE</div>
            <h2>
                Poprawa konwersji  to najlepszy sposób, by
                podwoić, a nawet potroić <span className={TextS.highlight}>zysk Twojej firmy</span>
            </h2>

            <p className={`${TextS.subtitle} ${ConversionSectionS.subtitle}`}>
                Sprawdź na kalkulatorze poniżej, jak ważny jest współczynnik konwersji.
            </p>


            <div className={ConversionSectionS.calculator}>

                <div className={ConversionSectionS.top}>
                    <div className={ConversionSectionS.content}>

                        <div className={ConversionSectionS.line}>
                            Ilość użytkowników:
                            <span className={ConversionSectionS.value}>100</span>
                        </div>

                        <div className={ConversionSectionS.line}>
                            Ilość klientów:
                            <span className={`${ConversionSectionS.value} ${checked && ConversionSectionS.improve}`} ref={clientsRef}></span>
                        </div>

                        <div className={ConversionSectionS.line}>
                            Średnia wartość zamówienia:
                            <span className={ConversionSectionS.value}>1000 zł</span>
                        </div>

                        <hr />

                        <div className={`${ConversionSectionS.line} ${ConversionSectionS.summary}`}>
                            Zysk:
                            <span className={`${ConversionSectionS.value} ${checked && ConversionSectionS.improve}`} ref={revenueRef}></span>
                        </div>

                    </div>

                    {/* image */}
                    {/* <img src={Phone} className={ConversionSectionS.phone_img} alt="Smartphone photo" /> */}
                    <video ref={videoRef} width="280" height="550" className={ConversionSectionS.phone_img}>
                        <source src={PhoneAnimation} type="video/webm" />
                        <source src={PhoneAnimationMp4} type="video/mp4" />
                    </video>

                </div>

                <div className={ConversionSectionS.bottom}>

                    <div className={ConversionSectionS.switch_wrapper}>
                        <div className={ConversionSectionS.label}>
                            Strona od
                            <img src={Logo} alt="WebProfessor logo" />
                        </div>

                        <div className={ConversionSectionS.toggle}>
                            <Switch
                                checked={checked}
                                onChange={() => setChecked(!checked)}
                                className={`${'Switch'} ${!checked && 'Switch-animate'}`}
                                onColor="#5CC994"
                                onHandleColor="#2bb673"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                height={15}
                                width={50}
                            />
                        </div>
                    </div>

                </div>

            </div>

        </section>
    );

}

export default ConversionSection;