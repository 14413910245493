import React, { useEffect, useState, useRef } from 'react';
import { useDebouncedCallback  } from 'use-debounce';

import CtaBtn from '../../ui-components/buttons/cta/cta';

import * as StandOutS from './stand-out-section.module.scss';
import * as TextS from '../../assets/styles/text.module.scss';
import * as WrapperS from '../../assets/styles/wrapper.module.scss'


const StandOutSection = () => {


    const [activeTile, setActiveTile] = useState(0);

    const tile1 = useRef();
    const tile2 = useRef();
    // const tile3 = useRef();

    const calcViewport = () => {

        //tile1
        const tile1_top = tile1.current.getBoundingClientRect().top;
        if(tile1_top <= 350 && tile1_top > 0){
            setActiveTile(1);
            return;
        }

        //tile2
        const tile2_top = tile2.current.getBoundingClientRect().top;
        if(tile2_top <= 350 && tile2_top > 0){
            setActiveTile(2);
            return;
        }

        //tile3
        // const tile3_top = tile3.current.getBoundingClientRect().top;
        // if(tile3_top <= 350 && tile3_top > 0){
        //     setActiveTile(3);
        //     return;
        // }

        setActiveTile(0);

    }

    const handleScroll = useDebouncedCallback(
        () => {

            const tile1_top = tile1.current.getBoundingClientRect().top;
            const tile2_top = tile2.current.getBoundingClientRect().top;

            if(tile1_top < window.innerHeight && tile2_top > 0){
                calcViewport();
            } else {
                setActiveTile(0);
            }

        }, 10
    )

    useEffect(() => {

        window.addEventListener("scroll", handleScroll);

    }, [handleScroll])

    return (
        <div className={StandOutS.bg}>

            <section className={`${StandOutS.section_wrapper} ${WrapperS.apply}`}>

                <div className={TextS.before_title}>JAKOŚĆ NIESPOTYKANA U KONKURENCJI</div>
                <h2>Co dokładnie nas <span className={TextS.highlight}>wyróżnia?</span></h2>

                <p className={`${TextS.subtitle} ${StandOutS.subtitle}`}>
                    Dajemy z siebie wszystko, żeby inwestycja w stronę internetową jak najszybciej się
                    zwróciła, a nowa strona przynosiła Ci <b>wysokie zyski przez lata.</b>

                </p>

                {/* 01 */}
                <div ref={tile1} className={`${StandOutS.tile} ${activeTile === 1 && StandOutS.active}`}>

                    <div className={`${StandOutS.aside}`}>

                        <div className={`${StandOutS.number}`}><span>01</span></div>

                        <div className={StandOutS.dash_line}></div>

                    </div>

                    <div className={StandOutS.content}>

                        <h3>Nieszablonowe podejście - mówimy stanowcze NIE szablonom</h3>

                        <p>
                            Wiele firm traktuje tworzenie stron internetowych jak produkcję masową.
                            Kilka zmian w szablonie i gotowe! Kolejna strona-klon, która nigdy się
                            nie wybije i zdobić będzie 47 pozycję w Google.
                        </p>

                        <p>
                            U nas jest inaczej. Do każdej strony podchodzimy indywidualnie i poświęcamy tyle czasu, ile trzeba aby miała ona
                            jak najwyższe szanse na osiągnięcie sukcesu.
                        </p>

                    </div>

                </div>

                {/* 02 */}
                <div ref={tile2} className={`${StandOutS.tile} ${activeTile === 2 && StandOutS.active}`}>

                    <div className={StandOutS.aside}>

                        <div className={StandOutS.number}><span>02</span></div>

                        <div className={StandOutS.dash_line}></div>

                    </div>

                    <div className={StandOutS.content}>

                        <h3>Nie układamy klocków, programujemy - czyli kilka słów o jakości</h3>

                        <p>
                            Wiele firm tworzy strony internetowe wykorzystując kreatory stron 'no-code'.
                            Praca w nich przypomina budowę 'z klocków' - gotowych komponentów które się układa i lekko modyfikuje.
                            Często twórca takiej strony nawet nie wie co się tak naprawdę dzieje w kodzie.
                        </p>

                        {/* <p>
                            Niestety większość firm idzie na skróty i wykorzystuje takie kreatory.
                            Zastanów się czy warto im płacić skoro ze zrobieniem strony w takim programie bez problemu poradzisz sobie sam!
                        </p> */}

                        <p>
                            Jeśli zależy Ci na stronie, która przynosi rezultaty trzeba postawić na profesjonalne rozwiązania.
                            Na doświadczonych programistów, którzy od podstaw napiszą szybki i bezpieczny kod dopasowany idealnie do Twoich potrzeb.
                        </p>

                    </div>

                </div>

                {/* 03
                <div ref={tile3} className={`${StandOutS.tile} ${activeTile === 3 && StandOutS.active}`}>

                    <div className={StandOutS.aside}>

                        <div className={StandOutS.number}><span>03</span></div>

                        <div className={StandOutS.dash_line}></div>

                    </div>

                    <div className={StandOutS.content}>

                        <h3>Gwarancja 100% satysfakcji. Bez limitu poprawek!</h3>

                        <p>
                            Nie spoczniemy dopóki nie stworzymy takich projektów, które będą Ci w pełni odpowiadały.
                        </p>

                        <p>
                            Jak to działa? Klient płaci drugą ratę tylko wtedy, gdy jest zadowolony z osiągniętego rezultatu.
                            Wszystkie warunki zawsze są dokładnie opisane przy wycenie. Bez żadnych haczyków ani ukrytych opłat.
                        </p>


                    </div>

                </div> */}

                <CtaBtn classes={StandOutS.cta_btn}>Szybka wycena</CtaBtn>

            </section>

        </div>
    );
}

export default StandOutSection;