// extracted by mini-css-extract-plugin
export var bg = "stand-out-section-module--bg--1U0wX";
export var section_wrapper = "stand-out-section-module--section_wrapper--33vvz";
export var subtitle = "stand-out-section-module--subtitle--2msV6";
export var tile = "stand-out-section-module--tile--BTowM";
export var active = "stand-out-section-module--active--3CllO";
export var aside = "stand-out-section-module--aside--70mcp";
export var number = "stand-out-section-module--number--dECKo";
export var dash_line = "stand-out-section-module--dash_line--2e7p8";
export var content = "stand-out-section-module--content--2RMy7";
export var cta_btn = "stand-out-section-module--cta_btn--REmNY";