import React, { useState } from 'react';

import * as FaqElementS from './faq-element.module.scss';

import plus from '../../../assets/images/Plus.svg';

const FaqElement = ({question, children}) => {

    const [active, setActive] = useState(false);

    return (
        <li className={`${FaqElementS.element_wrapper} ${active && FaqElementS.active}`}>
            <div
                className={FaqElementS.top_bar}
                onClick={() => setActive(prev => !prev)}>
                <h3>
                    {question}
                </h3>
                <img src={plus} alt="Open/close image" />
            </div>
            <div
                className={`${FaqElementS.content}`}
            >
            {children}
            </div>
        </li>
    );
}

export default FaqElement;