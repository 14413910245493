import React, { useState } from 'react';
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image';


import * as OpinionsS from './opinions.module.scss';
import * as TextS from '../../assets/styles/text.module.scss';
import * as WrapperS from '../../assets/styles/wrapper.module.scss'

const Opinions = () => {

    const data = useStaticQuery(graphql`
        query {
            ortogo: file(relativePath: { eq: "images/opinions/ortogo.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, formats: [JPG], quality: 80)
                }
            }
            remi: file(relativePath: { eq: "images/opinions/remi.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, formats: [JPG], quality: 80)
                }
            }
            gastrostrategy: file(relativePath: { eq: "images/opinions/gastrostrategy.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, formats: [JPG], quality: 80)
                }
            }
            hypnomental: file(relativePath: { eq: "images/opinions/hypnomental.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, formats: [JPG], quality: 80)
                }
            }
            argotech: file(relativePath: { eq: "images/opinions/argotech.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, formats: [JPG], quality: 80)
                }
            }
            notariuszegt: file(relativePath: { eq: "images/opinions/notariuszegt.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, formats: [JPG], quality: 80)
                }
            }
            pwkamar: file(relativePath: { eq: "images/opinions/pwkamar.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, formats: [JPG], quality: 80)
                }
            }
            czarnywaz: file(relativePath: { eq: "images/opinions/czarnywaz.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, formats: [JPG], quality: 80)
                }
            }
        }
    `);

    const opinions = [
        {
            image: data.ortogo.childImageSharp.gatsbyImageData,
            name: 'Karol\u00a0Sierpatowski',
            job_title: 'Centrum\u00a0Medyczne\u00a0OrtoGo',
            url: 'https://ortogo.pl',
            content: 'Korzystamy z usług firmy WebProfessor przy tworzeniu i utrzymaniu naszej strony ortogo.pl. To był dobry wybór! Chcemy dostarczać rzetelne informacje naszym Pacjentom a bez współpracy z profesjonalistami byłoby to niezwykle trudne.'
        },
        {
            image: data.remi.childImageSharp.gatsbyImageData,
            name: 'Karolina\u00a0Kopeć',
            job_title: 'RemiMed',
            url: 'https://remimed.io',
            content: 'Bezproblemowa i sprawna współpraca. Profesjonalne podejście i doskonała komunikacja zdecydowanie ułatwiły proces tworzenia strony. Jesteśmy bardzo zadowoleni z efektu końcowego projektu i w przyszłości na pewno zdecydujemy się jeszcze na współpracę z firmą WebProfessor.'
        },
        {
            image: data.gastrostrategy.childImageSharp.gatsbyImageData,
            name: 'Paweł\u00a0Leśniak',
            job_title: 'Gastrostrategy',
            url: '',
            content: 'Współpracę z firmą WebProfessor zacząłem około rok temu przy moim pierwszym projekcie. Zostałem bardzo sprawnie i profesjonalnie przeprowadzony przez cały proces tworzenia strony. Od wstępnej konsultacji poprzez wspólne stworzenia makiety aż po projekty graficzne strony i pełne wsparcie w zakresie user experience. Dzięki wiedzy, kompetencjom i terminowości Pana Rafała, jestem wielce zadowolonym klientem i polecam  w pełni firmę WebProfessor! Aktualnie kończymy budowę kolejnej strony.'
        },
        {
            image: data.hypnomental.childImageSharp.gatsbyImageData,
            name: 'Patryk\u00a0Bątkowski',
            job_title: 'Hypnomental',
            url: 'https://hypnomental.pl',
            content: 'Z czystym sumieniem mogę polecić współpracę z firmą WebProfessor, bo to naprawdę przyjemne doświadczenie. Bezproblemowe, rzetelne i pełne zaangażowania podejście to coś, co ich wyróżnia. Wszystkie moje oczekiwania zostały spełnione bardzo sprawnie i z dużą elastycznością. Rafał, z którym miałem przyjemność współpracować to nie tylko utalentowany programista, ale także profesjonalista i prawdziwy El Profesor w swoim fachu!'
        },
        {
            image: data.notariuszegt.childImageSharp.gatsbyImageData,
            name: 'Sylwia\u00a0Gomółka',
            job_title: 'Kancelaria\u00a0Notarialna',
            url: 'https://notariuszegt.pl',
            content: ''
        },
        {
            image: data.argotech.childImageSharp.gatsbyImageData,
            name: 'Artur\u00a0Golec',
            job_title: 'Argo-tech',
            url: 'https://argotech.pl',
            content: ''
        },
        {
            image: data.czarnywaz.childImageSharp.gatsbyImageData,
            name: 'Michał\u00a0Onuszko',
            job_title: 'Grupa\u00a0rekonstrukcji\u00a0historycznej',
            url: 'https://czarnywaz.pl',
            content: ''
        },
        {
            image: data.pwkamar.childImageSharp.gatsbyImageData,
            name: 'Robert\u00a0Choroś',
            job_title: 'PWKamar',
            url: 'https://pwkamar.pl',
            content: 'Bardzo dobrze wspominam współpracę. WebProfessor charakteryzuje się profesjonalnym podejściem do klienta, a komunikacja z wykonawcą jest bardzo dobra, dzięki czemu projekt został ukończony bez opóźnień, a efekt końcowy spełnia początkowe oczekiwania. Zdecydowanie polecam!'
        }
    ]


    const [opinionId, setOpinionId] = useState(0);


    const prevOpinion = () => {

            //change content
            if(opinionId === 0){
                setOpinionId(opinions.length - 1);
            } else {
                setOpinionId(opinion_id => ((opinion_id - 1) % opinions.length));
            }
    }


    const nextOpinion = () => {

        //change content
        setOpinionId(opinion_id => ((opinion_id + 1) % opinions.length));

    }


    return (
        <div className={OpinionsS.wrapper}>
                <button
                    onClick={() => prevOpinion()}
                    className={`${OpinionsS.prev} ${OpinionsS.nav_btn}`}
                    title='Poprzednia opinia'></button>

                <button
                    onClick={() => nextOpinion()}
                    className={`${OpinionsS.next} ${OpinionsS.nav_btn}`}
                    title='Następna opinia'></button>


                <div className={OpinionsS.opinion}>

                    <div className={OpinionsS.photo_desktop}>
                        <GatsbyImage
                            className={OpinionsS.img}
                            image={opinions[opinionId].image}
                            alt='Client photo'
                        />

                        <div className={OpinionsS.caption}>
                            <b><span dangerouslySetInnerHTML={{ __html: opinions[opinionId].name }} />,</b><br/>
                            <span dangerouslySetInnerHTML={{ __html: opinions[opinionId].job_title }} />
                        </div>

                    </div>

                    <div className={OpinionsS.content}>

                        <div className={OpinionsS.photo_mobile}>
                            <GatsbyImage
                                className={OpinionsS.img}
                                image={opinions[opinionId].image}
                                alt='Client photo'
                            />

                            <div className={OpinionsS.caption}>
                                <b>{opinions[opinionId].name},</b><br/>
                                {opinions[opinionId].job_title}
                            </div>

                            <button
                            onClick={() => prevOpinion()}
                            className={`${OpinionsS.prev} ${OpinionsS.nav_btn} ${OpinionsS.mobile_btn}`}
                            title='Poprzednia opinia'></button>

                            <button
                            onClick={() => nextOpinion()}
                            className={`${OpinionsS.next} ${OpinionsS.nav_btn} ${OpinionsS.mobile_btn}`}
                            title='Następna opinia'></button>

                        </div>

                        <div className={OpinionsS.caption_desktop}>
                            <b><span dangerouslySetInnerHTML={{ __html: opinions[opinionId].name }} />,</b><br/>
                            <span dangerouslySetInnerHTML={{ __html: opinions[opinionId].job_title }} />
                        </div>

                        <p>{opinions[opinionId].content}</p>

                        <div className={OpinionsS.action_wrapper}>

                            {opinions[opinionId].url !== '' &&
                                <a href={opinions[opinionId].url} className={OpinionsS.realisation_btn} target="_blank" rel="noopener">
                                    Zobacz realizację online
                                </a>
                            }

                            <div className={OpinionsS.google}>
                                Sprawdź również opinie o nas w&nbsp;
                                <a href="https://www.google.pl/search?ved=1t:65428&hl=pl&q=%F0%9F%92%BBWebProfessor+%7C+Profesjonalne+strony+internetowe+%7C+Kompleksowa+obs%C5%82uga+firm+w+sieci.&ludocid=11879306794475101406&lsig=AB86z5W0zlEKXcGFxs32Yez0pR1g#fpstate=lie"
                                    target="_blank" rel="noopener">
                                    Google!&nbsp;(5.0)
                                </a>
                            </div>

                        </div>


                    </div>

                </div>

        </div>
    );
}

export default Opinions;
