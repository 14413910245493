// extracted by mini-css-extract-plugin
export var section_wrapper = "offer-section-module--section_wrapper--1Fy3B";
export var header = "offer-section-module--header--1VLNV";
export var subtitle = "offer-section-module--subtitle--1DFl4";
export var cta_desktop = "offer-section-module--cta_desktop--3boUr";
export var content = "offer-section-module--content--3_MXM";
export var tile = "offer-section-module--tile--yNyYG";
export var active = "offer-section-module--active--3bUUM";
export var number = "offer-section-module--number--1HGuZ";
export var tile_header = "offer-section-module--tile_header--10hZt";
export var icon = "offer-section-module--icon--1BZ8a";
export var cta_mobile = "offer-section-module--cta_mobile--3uUjD";