// extracted by mini-css-extract-plugin
export var wrapper = "opinions-module--wrapper--3LpsQ";
export var nav_btn = "opinions-module--nav_btn--L6cZ6";
export var mobile_btn = "opinions-module--mobile_btn--pZVEu";
export var next = "opinions-module--next--1Uv5d";
export var prev = "opinions-module--prev--1rhTk";
export var opinion = "opinions-module--opinion--2_amJ";
export var photo_desktop = "opinions-module--photo_desktop--3Tllm";
export var img = "opinions-module--img--3nRUI";
export var caption = "opinions-module--caption--1vXPX";
export var content = "opinions-module--content---HxQ-";
export var photo_mobile = "opinions-module--photo_mobile--2G0gR";
export var caption_desktop = "opinions-module--caption_desktop--13uHO";
export var action_wrapper = "opinions-module--action_wrapper--26g5d";
export var realisation_btn = "opinions-module--realisation_btn--3-kwb";
export var google = "opinions-module--google--3kHWG";